<template>
  <div class="">
    <div class="my-card">
      <img
        class="col-md-12 car-info"
        :src="require('@/assets/images/' + carData.img)"
        :onerror="`this.src='${defaultImg}'`"
        alt="Image"
        :title="carData.name"
      />
      <h2 class="text-center lead">{{ carData.name }}</h2>
    </div>
    <div class="">
      <div class="table-container">
        <table class="my-table">
          <tbody>
            <tr>
              <th>{{ $t("carDis.model") }}</th>
              <td>{{ carData.model }}</td>
            </tr>
            <tr>
              <th>{{ $t("carDis.foul") }}</th>
              <td>{{ carData.foul }}</td>
            </tr>
            <tr>
              <th>{{ $t("carDis.height") }}</th>
              <td>{{ carData.height }}</td>
            </tr>
            <tr>
              <th>{{ $t("carDis.type") }}</th>
              <td>{{ carData.type }}</td>
            </tr>
            <tr>
              <th>{{ $t("carDis.height") }}</th>
              <td>{{ carData.height }}</td>
            </tr>
            <tr>
              <th>{{ $t("carDis.type") }}</th>
              <td>{{ carData.type }}</td>
            </tr>
            <tr>
              <th>{{ $t("carDis.power") }}</th>
              <td>{{ carData.power }}</td>
            </tr>
            <tr>
              <th>{{ $t("carDis.capacity") }}</th>
              <td>{{ carData.capacity }}</td>
            </tr>
            <tr>
              <th>{{ $t("carDis.area") }}</th>
              <td>{{ carData.area }}</td>
            </tr>
            <tr>
              <th>{{ $t("carDis.number") }}</th>
              <td>{{ carData.number }}</td>
            </tr>
            <tr>
              <th>{{ $t("carDis.number1") }}</th>
              <td>{{ carData.number1 }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import DEFAULT_IMG from "@/assets/images/slider3.png";

export default {
  name: "Info",
  components: {},
  props: ["carData"],
  data() {
    return {
      defaultImg: DEFAULT_IMG,
    };
  },
  async created() {
    if (!this.carData) {
      this.$router.push("/").catch(() => {});
    }
  },
};
</script>
<style scoped lang="scss">
.car-info {
  max-height: 60vh !important;
}
</style>
